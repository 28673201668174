<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconDownTriangle',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
    aria-hidden="true"
  >
    <g>
      <title>
        Downward Facing Triangle Icon
      </title>
      <path
        d="m16.362481,59.800649l82.999999,101.109094l82.999999,-101.109094l-165.999998,0z"
        stroke-width="1.5"
        :fill="color"
        :stroke="color"
      />
    </g>
  </svg>
</template>
